.search-container {
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 4px 4px 4px 0 rgba(34, 60, 80, 0.2);
    height: 60px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 0 20px;
}
@media (min-width: 1400px) {
    .search-container {
        max-width: 1560px;
    }
}
.search-row {
    width: 100%;
}
.input-holder {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
}
.input-group {
    max-width: 500px;
    font-size: 18px;
}
.input-holder__input-field {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #1d3557ff;
    border-radius: 0;
    color: #1d3557ff!important;
    font-size: 18px;
    height: 42px;
}
.input-holder__input-field:focus {
    background-color: transparent;
    color: #111;
    box-shadow: none;
    border: 1px solid;
    border-radius: 4px!important;
}
.input-holder__input-button {
    background-color: transparent;
    border-radius: 4px!important;
    border-color: #1d3557ff;
    border-width: 1px;
    margin-left: 10px!important;
    padding-left: 20px;
    padding-right: 20px;
    color: #1d3557ff;
}
.input-holder__input-button:hover{
    background-color: #1d3557ff;
    color: #fff;
    border-color: #fff;
}
.button-holder {
    display: flex;
    align-items: center;
}
.button-holder__single-button {
    margin-right: 8px;
}
.button-holder a {
    margin-right: 16px;
}
/* .button-holder a button {
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 20px;
    border: 1px solid #1d3557ff;
    color: #1d3557ff;
    font-size: 18px;
    height: 42px;
}
.button-holder a button:hover {
    background-color: #1d3557ff;
    color: #fff;
    border-color: #fff;
}
.button-holder button {
    background-color: transparent;
    border-radius: 4px;
    padding: 5px 20px;
    border: 1px solid #1d3557ff;
    color: #1d3557ff;
    font-size: 18px;
    height: 42px;
}
.button-holder button:hover {
    background-color: #1d3557ff;
    color: #fff;
    border-color: #fff;
}
.button-holder button:focus {
    box-shadow: none;
    background-color: #1d3557ff;
    color: #fff;
    border-color: #fff;
} */