.modular-edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.6); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.map-item {
    padding: 1em;
    border-bottom: 1px solid black;
}

.modal-content {
    background-color: #fff;
    border: none;
    color: #666666;
    box-shadow: 4px 4px 4px 0 rgba(34, 60, 80, 0.2);
}
.modal-header {
    border-color: #333;
}
.modal-footer {
    border-color: #333;
}
.form__block {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
}
.block__edit-label {
    color: #1d3557ff;
    text-transform: capitalize;
}
.form-label {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}
.form__readOnly {
    background-color: transparent!important;
    border: none;
    border-bottom: 1px solid #666666;
    border-radius: 0;
    padding-left: 0;
    color: #666666;
}
.form__input {
    border: none;
    border-radius: 0;
    padding-left: 0;
    border-bottom: 1px solid #1d3557ff;

}
.form__input:focus {
    box-shadow: none;
    outline: none;
}
.form__readOnly:focus {
    box-shadow: none;
    outline: none;
}
.form__input-checkbox {
    width: 100%;
    height: 100%;
    border: none;
}
.form__input-checkbox .form-check-input {
    margin-top: 8px;
    margin-left: 0;
    height: 32px;
    width: 32px;
    border-color: #5d5386;
}
.form__input-checkbox .form-check-input:checked {
    background-color: #5d5386;
    border-color: #5d5386;
}
.form__input-number {
    width: 60px;
    border: 1px solid #5d5386;
    padding: 0;
    padding-left: 4px;
    margin-top: 8px;
    font-size: 24px;
    border-radius: 0.25em;
}
/*.form__btn-back {*/
/*    background-color: #fff;*/
/*    color: #1d3557ff;*/
/*    border: 1px solid #1d3557ff;*/
/*}*/
/*.form__btn-back:hover {*/
/*    background-color: #457b9dff;*/
/*    color: #fff;*/
/*    border: 1px solid #fff;*/
/*}*/

/*.form__btn-save:hover {*/
/*    background-color: #457b9dff;*/
/*    color: #fff;*/
/*    border: 1px solid #457b9dff;*/
/*}*/
.modal-footer {
    margin: 0;
}
.form__btn-save {
    margin-left: 8px;
}
.form-control:disabled {
    background-color: #fff;
}
.create-user-form-body__input-group {
    display: flex;
    flex-direction: column;
}
.create-user-form-body__input-group .btn {
    /*margin-top: 8px;*/
    max-width: 160px;
}
.modal-plus-button {
    padding: 4px 24px;
}
.modal-plus-button:hover {
    background-color: #69CD00;
    border-color: #69CD00;
}
.modal-plus-button:focus {
    background-color: #5d5386;
    border-color: #5d5386;
}
.modal-plus-icon {
    height: 30px;
    width: 30px;
}
.modal-minus-btn {
    height: 100%;
    width: 100%;
    max-width: 40px!important;
    margin-left: 1em;
    padding: 0!important;
    margin-top: 0;
}
.modal-minus-btn:hover {
    background-color: #FE195E;
    border-color: #FE195E;
}
.modal-minus-icon {
    height: 24px;
    width: 24px;
}
.modal-title {
    text-transform: capitalize;
    margin-bottom: 0;
}
.file-type__radio {
    margin: 16px;
}
.import-modal__additional-fields-button {
    margin: 16px 0;
}
.input-group__input-field {
    margin: 4px 0;
}