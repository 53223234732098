.sidebar-container {
    height: 100%;
    align-items: stretch;
    display: flex;
    max-width: 100%;
    max-width: 240px;
}
.sidebar-container .row {
    align-items: stretch;
}
.panel-body {
    border-radius: 2px;
    /* background-color: #fff; */
    padding: 20px;
    padding-bottom: 40px;
    box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.2);
    /* border: 1px solid #ccc; */
    height: 100%;
    width: 100%;
    max-width: 100%;
    /* max-height: 95vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.panel-body__user {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
    border: 1px solid #ccc;
    box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.2);
    padding: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #1d3557ff;
    background-color: #fff;
}
.user__icon {
    margin-right: 16px;
    border-radius: 50%;
    border: 1px solid #333333;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon__img {
    height: 20px;
    width: 20px;
}
.panel-body__models-list {
    padding: 8px;
    height: auto;
    margin-bottom: 40px;
}
.model-list__title {
    color: palevioletred;
    font-size: 20px;
    font-weight: 700;
}
.models-list__model {
    text-decoration: none;
    /* color: #1d3557ff; */
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 4px;
    
}
/* .panel-body__buttons {
    margin-left: auto;
    margin-right: auto;
} */
.buttons__button {
    margin-right: 10px;
}
.buttons__button:hover {
    border-color: #e4e8f0;
}

.logout-button {
    display: flex;
    align-items: stretch;
    width:100%;
    min-width: 200px;
    
}
.logout-button button {
    align-items: stretch;
    width: 100%;
}
.logout-button button:hover {
    border-color: #e4e8f0;
}