.form {
    margin: auto;
    margin-top: 120px;
    max-width: 500px;
    background-color: transparent;
    padding: 20px;
    border-radius: 4px;
    color: #333;
}
.form__line {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 24px;
}
.form__line-title {
    margin-bottom: 24px;
}
.line__input {
    background-color: #fff;
    border-color: #111;
    height: 48px;
    font-size: 18px;
}
.line__input:focus {
    box-shadow: none;
    border-color: #111;
}
.line-checkbox {
    display: flex;
    margin-bottom: 20px;
}
.form-check-input {
    border-color: #111;
}
.form-check-input:focus {
    box-shadow: none;
}
.line-checkbox a {
    color: #333!important;
}
.form__button, .form__button:focus {
    margin-right: 20px;
    color: #fff;
    background-color: #1d3557ff;
    border: 1px solid #fff;
    box-shadow: none;
}
.form__button:hover {
    color: #1d3557ff;
    background-color: #fff;
    border: 1px solid #1d3557ff;
}
.login__error-message {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: #e63946ff;
    margin-bottom: 8px;
}