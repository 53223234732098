.pagination-container {
    padding: 0;
    margin: 20px auto;
}
@media (min-width: 1400px) {
    .pagination-container {
        max-width: 1560px;
    }
}
.pagination {
    background-color: #fff;
    box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.2);
    border: 1px solid #ccc;
    padding: 12px 86px;
    border-radius: 4px;
}
.pagination__select {
    width: 160px;
    margin-right: 8px;
}
.pagination__button {
    margin: 0 8px;
}
