.users-table-wrapper {
    margin: auto;
    border-radius: 4px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.2);
    border: 1px solid #ccc;
    overflow-x: scroll;
    height: calc(100vh - 282px);
}
@media (min-width: 1400px) {
    .users-table-wrapper {
        max-width: 1560px;
    }
}
.table-wrapping {
    width: auto;
    max-width: none;
    height: 100%;
}
.single-table-row {
    align-items: center;
    color: #98a8b4;
    font-size: 18px;
    border:none;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #ccc;
    border-radius: 0!important;
    width: fit-content;
    padding: 12px;
    min-width: 100%;
}
.single-user__block {
    width: 20%;
    margin-right: 40px;
    min-width: 220px;
    overflow-x: auto;
}
.single-user__button-block {
    display: flex;
    justify-content: flex-end;
}
/* .single-user__edit-button {
    background-color: transparent;
    border-color: #1d3557ff;
    color: #1d3557ff;
    margin-right: 10px;
}
.single-user__edit-button:hover {
    background-color: #1d3557ff;
    color: #fff;
    border-color: #fff;
}
.single-user__edit-button:focus {
    border-color: #1d3557ff;
    background-color: transparent;
    color: #1d3557ff;
    box-shadow: none
} */
.user-table__labels {
    border: none;
}
.labels__label {
    font-size: 20px;
    font-weight: 500;
    width: 20%;
    margin-right: 40px;
    text-transform: capitalize;
}
.page-link {
    color: #1d3557ff!important;
    border-color: #1d3557ff;
}
.page-table__error {
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 600;
    color: #1d3557ff;
}