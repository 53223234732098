.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.main-content-section {
    height: 100%;
    align-items: stretch;
}
.content-section-body {
    background-color: #f2f3f8;
    opacity: 100;
    animation: expand .5s ease-in-out;
}
@keyframes expand {
    from {
        opacity: 0;

    }
}

/* .content {
    opacity: 100;
    animation: expand .5s ease-in-out;
} */