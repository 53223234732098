.navbar-holder {
    background-color: #fff;
    box-shadow: 0 1px 15px 1px rgb(52 40 104 / 8%);
    height: 70px;
}
.navbar-header {
    margin-left: 16px;
}
.nav-menu {
    margin-right: 48px;
}
.top-panel-user-icon {
    height: 40px;
    width: 40px;
}
.header__link-logo {
    font-size: 24px;
    font-weight: 700;
}