
.dummy__home-page {
    margin-top: 180px;
}
.home-page__title {
    font-size: 48px;
}
.dummy-buttons {
    width: fit-content;
    justify-content: end;

    margin: auto;   
}
.dummy-buttons__button {
    margin: 20px;
    width: 200px;
    height: 50px;
    font-size: 24px;
    font-weight: 600;
    background-color: transparent;
    border-radius: 10px;
}